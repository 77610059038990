/* eslint-disable no-undef */
import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

Sentry.init({
	dsn: 'https://0efcb915c2a543438f29585fea49da34@o1167493.ingest.sentry.io/6258704',
	integrations: [new BrowserTracing()],
	environment: process.env.NODE_ENV,
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.2 : 0.1,
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
